import jwtDecode from "jwt-decode";

export const statusOptions = [0, 1];

export const rolesOptions = [99, 1, 2];

export function translateRoleNumberToString(value) {
  if (value === 1 || value === "1") return "Supervisor";
  if (value === 2 || value === "2") return "Terapeuta";
  if (value === 99 || value === "99") return "Master";
  return value;
}

export function translateStatus(value) {
  if (value === 1 || value === "1") return "Ativo";
  if (value === 0 || value === "0") return "Inativo";
  return value;
}

export function parseDateFromApi(dateString) {
  if (!dateString) return "";
  return dateString.split("T")[0];
}

export function formatDateFromApi(dateYYYYMMDD, separator = "/") {
  const splitedData = dateYYYYMMDD.includes("T")
    ? dateYYYYMMDD.split("T")[0]
    : dateYYYYMMDD;
  const dates = splitedData.split("-");
  const day = dates[2].padStart(2, "0");
  const month = dates[1].padStart(2, "0");
  const year = dates[0].padStart(4, "0");

  return `${day}${separator}${month}${separator}${year}`;
}

export function userLogged() {
  const token = localStorage.getItem("TOKEN");

  if (token) {
    const user = jwtDecode(token).user;

    return user;
  } else {
    return null;
  }
}

export function filterRoutesByRole(routes) {
  const user = userLogged();

  if (user) {
    return routes.filter(
      (route) => !route.roles || route.roles.includes(Number(user.role))
    );
  } else {
    return routes;
  }
}

export function translateFilePatientsOptions(value) {
  if (value && String(value) === "1") return "Avaliações";
  if (value && String(value) === "2") return "PIC e Relatórios";
  return value;
}

// calendar
export const recurrenceOptions = ["unique", "weekly", "monthly"];

export function translateRecurrenceOptions(value) {
  if (value === "unique") return "Único";
  if (value === "weekly") return "Semanal";
  if (value === "monthly") return "Mensal";
  return value;
}

// attendance folder - program
export const programTypeOptions = [
  "Linha de base",
  "Intervenção",
  "Manutenção",
];
export const programHelpTypeOptions = [
  "Ecóica/verbal",
  "Ajuda física total",
  "Ajuda física parcial",
  "Ajuda gestual",
  "Ajuda necessária",
  "Independente (sem ajuda)",
];

// patient - meeting
export const meetingTypeOptions = [
  "Escolar",
  "Familiar",
  "Outros profissionais",
];

// patient - anamnesis
export const radioOptionsReference = [
  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "female",
    label: "Feminino",
  },
  {
    value: "independent",
    label: "Independente",
  },
];

export const radioOptionsDiagnosis = [
  {
    value: "confirmed",
    label: "Confirmado",
  },
  {
    value: "hipotesis",
    label: "Hipótese",
  },
  {
    value: "independent",
    label: "Independente",
  },
];

export const radioOptionsBirthType = [
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "cesarean",
    label: "Cesárea",
  },
];

export const radioOptionsTrueFalse = [
  {
    value: "yes",
    label: "Sim",
  },
  {
    value: "no",
    label: "Não",
  },
];

export const radioOptionsEyeContact = [
  {
    value: "yes",
    label: "Sim",
  },
  {
    value: "no",
    label: "Não",
  },
  {
    value: "rarely",
    label: "Pouco",
  },
];

export const radioOptionsSphincterControl = [
  {
    value: "yes",
    label: "Sim",
  },
  {
    value: "no",
    label: "Não",
  },
  {
    value: "acquisition",
    label: "Em aquisição",
  },
];

export const radioOptionsManipulationHabits = [
  {
    value: "always",
    label: "Sempre",
  },
  {
    value: "sometimes",
    label: "As vezes",
  },
  {
    value: "rarely",
    label: "Raramente",
  },
];

export const radioOptionsSensoryAlteration = [
  {
    value: "visuals",
    label: "Visuais",
  },
  {
    value: "tactile",
    label: "Táteis",
  },
  {
    value: "gustatory",
    label: "Gustativas",
  },
  {
    value: "olfactory",
    label: "Olfativas",
  },
  {
    value: "proprioceptive",
    label: "Proprioceptivas",
  },
  {
    value: "auditory",
    label: "Auditivas",
  },
];


// patient - file
// const radioOptionsFile = [
//   {
//     value: "1",
//     label: "Avaliações",
//   },
//   {
//     value: "2",
//     label: "PIC e Relatórios",
//   },
// ];

export const ufOptions = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];
